import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getYouTubePlaylist } from "Api";
import {
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Card,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import ReactPlayer from "react-player/youtube";

function YouTubeViewer({ playlistID }) {
  const [videoURL, setVideoURL] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (url) => {
    setVideoURL(url);
    setIsPlaying(true);
  };

  const { data: youtubeData, isLoading } = useQuery({
    queryKey: ["youtube"],
    queryFn: () => getYouTubePlaylist(playlistID),
    onSuccess: (youtubeData) => {
      setVideoURL(youtubeData.data.items[0].snippet.resourceId.videoId);
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    setIsPlaying(false);
    setVideoURL(youtubeData?.data?.items?.[0]?.snippet?.resourceId?.videoId);
  }, [youtubeData?.data?.items]);

  if (isLoading) {
    return null;
  }

  return (
    <Grid
      container
      sx={{ width: "100%", alignItems: "center", height: "auto" }}
    >
      {!isMobile && (
        <Grid item xs={9}>
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=${videoURL}&rel=0`}
            width='100%'
            height='calc(100vh - 400px)'
            style={{ minHeight: "300px", maxHeight: "700px" }}
            controls
            playing={isPlaying}
            fallback={<Skeleton width='100%' height='calc(100vh - 400px)' />}
          />
        </Grid>
      )}
      {isMobile ? (
        <Grid container spacing={3} justifyContent='center'>
          {youtubeData?.data?.items?.map((item) => (
            <Grid
              item
              xs={12}
              key={item?.snippet?.resourceId?.videoId}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReactPlayer
                url={`https://www.youtube.com/watch?v=${item?.snippet?.resourceId?.videoId}&rel=0`}
                width='95vw'
                controls
                light
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          sx={{
            height: "calc(100vh - 400px)",
            maxHeight: "700px",
            overflowY: "scroll",
            overflowX: "hidden",
            minHeight: "300px",
            width: "100%",
          }}
        >
          <ImageList
            cols={1}
            sx={{
              margin: "0px",
              paddingLeft: "15px",
              paddingRight: "0px",
              overflow: "visible",
            }}
            gap={20}
          >
            {youtubeData?.data?.items.map(
              (item) =>
                item?.snippet?.thumbnails?.high?.url && (
                  <Card key={item.id} elevation={9}>
                    <ImageListItem sx={{ cursor: "pointer" }}>
                      <img
                        src={item?.snippet?.thumbnails?.high?.url}
                        alt={item?.snippet?.title}
                        loading='lazy'
                        onClick={() =>
                          handleClick(item?.snippet?.resourceId?.videoId)
                        }
                      />
                      <ImageListItemBar title={item?.snippet?.title} />
                    </ImageListItem>
                  </Card>
                )
            )}
          </ImageList>
        </Grid>
      )}
    </Grid>
  );
}

export default YouTubeViewer;
