import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import PropTypes from "prop-types";
import MobileNavigation from "./MobileNavigation";
import DesktopNavigation from "./DesktopNavigation";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Typewriter from "typewriter-effect";
import { useMemo } from "react";

const defaultProps = {
  title: "Site Title",
  subtitle: "Site Subtitle",
  navigation: [],
  titleStyle: {},
  subTitleStyle: {},
  navigationStyle: {},
  menuStyle: {},
};

const leftGifs = [
  "https://24.media.tumblr.com/a8d91d9d6f197e126351464305f75dd7/tumblr_msitq5qMwW1sg2d49o1_1280.gif",
  "https://i.imgur.com/SBYCw.gif",
  "https://media.tenor.com/mx7P8NY2NysAAAAi/video-games-monkey.gif",
  "https://i.gifer.com/origin/d5/d5b9ae79f5254caaf0fdcf2affcec5b0_w200.gif",
  "https://www.icegif.com/wp-content/uploads/2023/03/icegif-1487.gif",
];

const rightGifs = [
  "https://64.media.tumblr.com/78fa347e411c30153d9df8d781d97ccd/tumblr_mnzlljXCmH1rn2utdo4_250.gifv",
  "https://media.tenor.com/XSyvNjfG68MAAAAi/video-games-monkey.gif",
  "https://img1.picmix.com/output/stamp/normal/3/5/8/4/2514853_da90c.gif",
  "https://media.tenor.com/a_JvvZ_a5dAAAAAj/sonic-fox.gif",
  "https://media3.giphy.com/media/z0hUv6bpBWUUOtShDf/giphy.gif?cid=ecf05e47q72i0uy2kkqikojd46ryygoubvogdljpd1l891op&ep=v1_stickers_search&rid=giphy.gif&ct=s",
];

const Header = ({
  navigation,
  title,
  subtitle,
  titleStyle,
  subTitleStyle,
  navigationStyle,
  menuStyle,
  menuProps,
  navigationTypographyProps,
  titleTypographyProps,
  subTitleTypographyProps,
  listItemProps,
  listItemButtonProps,
  listItemIconProps,
  listItemTextProps,
  pageTitleTypographyProps,
  listProps,
  showGifs,
  hideTextTitle,
  headerImage,
  disableOnClick,
  headlines,
  headlinesStyle,
}) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { pathname } = useLocation() || {};
  const homePath = navigation?.find((item) => item.home)?.path;

  const leftGif = useMemo(
    () => leftGifs[Math.floor(Math.random() * leftGifs.length)],
    []
  );

  const rightGif = useMemo(
    () => rightGifs[Math.floor(Math.random() * rightGifs.length)],
    []
  );

  const currentPageObject = navigation?.find((item) => {
    if (item.children) {
      return item.children.find((child) => child?.path === pathname);
    }
    return item.path === pathname;
  });

  const currentPageName = currentPageObject?.children
    ? currentPageObject.children?.find((child) => child?.path === pathname)
        ?.title
    : currentPageObject?.title;

  const filteredNavigation = navigation?.filter((item) => item.menu);

  return (
    <motion.div
      style={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <Grid container sx={{ zIndex: 100, marginBottom: isMobile ? 0 : "0px" }}>
        <Grid
          item
          xs={12}
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {!hideTextTitle && (
            <Typography
              variant='h2'
              fontWeight='bold'
              sx={{
                color: palette.text.primary,
                "&:hover": { color: palette.primary.main },
                paddingTop: ".5rem",
                textAlign: "center",
                fontSize: "clamp(1rem, 10vw, 4rem)",
                marginX: "4rem",
                cursor: "pointer",
                ...titleStyle,
              }}
              onClick={() => navigate(homePath)}
              {...titleTypographyProps}
            >
              {title.toUpperCase()}
            </Typography>
          )}
          <Grid
            container
            justifyContent={isMobile ? "center" : "space-between"}
          >
            {!isMobile && (
              //animate when the image changes
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -150, opacity: 0 }}
                transition={{ duration: 0.7 }}
                // key={leftGif}
              >
                <img
                  src={leftGif}
                  height='150px'
                  style={{ paddingTop: "10px" }}
                  width='auto'
                  alt='gif'
                />
              </motion.div>
            )}
            {headerImage && (
              <img
                src={headerImage?.src}
                alt={headerImage?.alt}
                onClick={disableOnClick ? undefined : () => navigate(homePath)}
                style={{
                  height: "150px",
                  paddingTop: "10px",
                  width: "auto",
                  maxWidth: "800px",
                  maxHeight: "150px",
                  objectFit: "cover",
                  objectPosition: "center",
                  cursor: disableOnClick ? undefined : "pointer",
                }}
              />
            )}
            {!isMobile && (
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -150, opacity: 0 }}
                transition={{ duration: 0.7 }}
                key={rightGif}
              >
                <img
                  src={rightGif}
                  height='150px'
                  style={{ paddingTop: "10px" }}
                  width='auto'
                  alt='gif'
                />
              </motion.div>
            )}
          </Grid>
        </Grid>
        {subtitle && (
          <Grid
            item
            xs={12}
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Typography
              variant='subtitle1'
              sx={{
                textAlign: "center",
                fontSize: "clamp(1rem, 5vw, 1.5rem)",
                ...subTitleStyle,
              }}
              color='text.secondary'
              {...subTitleTypographyProps}
            >
              {subtitle.toLowerCase()}
            </Typography>
          </Grid>
        )}
        {headlines?.length && !isMobile && (
          <Grid
            item
            xs={12}
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div style={headlinesStyle}>
              <Typewriter
                options={{
                  strings: headlines
                    .map((value) => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value),
                  autoStart: true,
                  loop: true,
                  skipAddStyles: false,
                  deleteSpeed: 15,
                }}
              />
            </div>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isMobile ? (
            <MobileNavigation
              navigation={filteredNavigation}
              listItemProps={listItemProps}
              listItemButtonProps={listItemButtonProps}
              listItemIconProps={listItemIconProps}
              listItemTextProps={listItemTextProps}
              listProps={listProps}
              showGifs={showGifs}
            />
          ) : (
            <DesktopNavigation
              navigation={filteredNavigation}
              navigationStyle={{
                paddingX: "2rem",
                marginTop: "2rem",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "18px",
                ...navigationStyle,
              }}
              menuStyle={{ fontSize: "16px", fontWeight: "bold", ...menuStyle }}
              menuProps={menuProps}
              navigationTypographyProps={navigationTypographyProps}
            />
          )}
        </Grid>
        <AnimatePresence mode='popLayout'>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: currentPageName ? 1 : 0 }}
            exit={{ x: -150, opacity: 0 }}
            transition={{ duration: 0.7 }}
            key={currentPageName}
            style={{ height: "42px", minWidth: "100px" }}
          >
            <Typography
              variant='h4'
              fontWeight='bold'
              color='primary'
              {...pageTitleTypographyProps}
            >
              {currentPageName}
            </Typography>
          </motion.div>
        </AnimatePresence>
        <Divider
          sx={{
            marginBottom: "10px",
            width: "100%",
          }}
        />
      </Grid>
    </motion.div>
  );
};

export default Header;

Header.defaultProps = defaultProps;

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.element,
      element: PropTypes.element,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          href: PropTypes.string,
          path: PropTypes.string,
          icon: PropTypes.element,
          element: PropTypes.element,
        })
      ),
    })
  ),
  titleStyle: PropTypes.object,
  subTitleStyle: PropTypes.object,
  navigationStyle: PropTypes.object,
  menuStyle: PropTypes.object,
};
