import { Music } from "Pages";
import { MusicNote } from "@mui/icons-material";

const MusicContent = {
  title: "Music",
  spotifyPlaylistURL:
    "https://open.spotify.com/playlist/1mjh4nK97hoFJImdEZeBEp?si=081278f7eae144e0",
  icon: <MusicNote />,
  path: "/music",
  hidden: true,
  // subMenu: false,
  menu: true,
  menuIndex: 1,
  element: <Music />,
};

export default MusicContent;
