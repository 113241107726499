import { Face } from "@mui/icons-material";
import { About } from "Pages";

const AboutContent = {
  title: "About",
  paragraph:
    "Video Game Alliance is a collective of nerds who happen to also be musicians. We perform with 6 to 9 musicians and offer two exciting experiences.  VGA is committed to bringing beloved video game music to life in a live setting \n\n VGA is a 6-piece band that re-imagines classic video game music in an improvisatory style.  You can hear selections from your favorite video games: Mario, Zelda, Pokemon, Chrono Trigger, and many more! \n\n MK Live is a 9-piece band dedicated to recreating the music of Mario Kart 8 with a twist.  YOU and your friends sign-up to race while we provide the soundtrack note for note. We provide the hardware and anyone is welcome regardless of age or skill!",
  bioName: "Video Game Alliance",
  image: {
    src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-95.jpeg",
  },
  imageAspectRatio: "4/3",
  icon: <Face />,
  path: "/about",
  home: false,
  hidden: false,
  menu: true,
  menuIndex: 0,
  element: <About />,
};

export default AboutContent;
