import { useState, useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "Components/Header";
import Footer from "Components/Footer";
import { HeaderContent } from "Content";
import {
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from "@mui/material";
import { useIsMobile } from "Hooks";
import navigation from "navigation";
import customTheme from "theme";
import "./App.css";

function App() {
  const isMobile = useIsMobile();
  const Home = navigation.find((item) => item.home)?.element;

  const initialColorMode = window.matchMedia("(prefers-color-scheme: dark)")
    .matches
    ? "dark"
    : "dark";
  const [mode, setMode] = useState(initialColorMode);

  useLayoutEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const colorScheme = event.matches ? "dark" : "dark";
        setMode(colorScheme);
      });
  }, []);

  const theme = createTheme({
    ...customTheme?.[mode],
    palette: {
      mode: mode,
      ...customTheme?.[mode]?.palette,
    },
  });

  const navigationWithoutElements = navigation.map((item) => {
    if (item.children) {
      return {
        ...item,
        children: item.children.map((child) => {
          return {
            ...child,
            element: undefined,
          };
        }),
      };
    }
    return {
      ...item,
      element: undefined,
    };
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth='lg'
        sx={{
          minWidth: "280px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: isMobile ? "calc(100% - 330px) !important" : undefined,
          minHeight: isMobile ? "calc(100% - 330px) !important" : undefined,
        }}
      >
        <>
          <Header {...HeaderContent} navigation={navigationWithoutElements} />
          <Routes>
            <Route path='/' element={Home} />
            {navigation.map(({ element, children, ...props }) => {
              if (children) {
                return children.map(
                  ({ element: childElement, ...childProps }) => (
                    <Route
                      key={childProps?.path}
                      element={childElement}
                      {...childProps}
                    />
                  )
                );
              }
              return <Route key={props?.path} element={element} {...props} />;
            })}

            <Route path='*' element={Home} />
          </Routes>
          <Footer />
        </>
      </Container>
    </ThemeProvider>
  );
}

export default App;
