import { useState, forwardRef } from "react";
import {
  TextField,
  Typography,
  Grid,
  Button,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { useIsMobile } from "Hooks";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const Contact = ({ message }) => {
  const { state } = useLocation();
  const isMobile = useIsMobile();

  const [formData, setFormData] = useState({
    firstName: { value: "", error: false },
    lastName: { value: "", error: false },
    email: { value: "", error: false },
    subject: { value: state?.formValues?.subject || "", error: false },
    message: { value: state?.formValues?.message || "", error: false },
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success",
    open: false,
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: { value, error: false },
    });
  };

  const validate = () => {
    const { firstName, lastName, email, subject, message } = formData;
    const hasFirstName = firstName.value.length > 0;
    const hasLastName = lastName.value.length > 0;
    const hasEmail = subject.value.length > 0;
    const hasSubject = subject.value.length > 0;
    const hasMessage = message.value.length > 0;
    const hasError =
      !hasFirstName || !hasLastName || !hasEmail || !hasSubject || !hasMessage;

    if (!hasError && validateEmail(email.value)) {
      return true;
    } else if (hasError) {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Please fill out all fields.",
      });
      setFormData({
        firstName: { ...firstName, error: !hasFirstName },
        lastName: { ...lastName, error: !hasLastName },
        email: { ...email, error: !hasEmail },
        subject: { ...subject, error: !hasSubject },
        message: { ...message, error: !hasMessage },
      });
      return false;
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: "Please enter a valid email.",
      });
      setFormData({
        ...formData,
        email: { ...email, error: true },
      });
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = Object.assign(
        ...Object.entries(formData).map((item) => ({
          [item[0]]: item[1].value,
        }))
      );
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          data,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setSnackbar({
              message: "Message sent successfully!",
              severity: "success",
              open: true,
            });
          },
          (error) => {
            setSnackbar({
              open: true,
              severity: "error",
              message: "An error occurred",
            });
          }
        );
    }
  };

  return (
    <motion.div
      style={{ opacity: 0, height: "100%" }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          width: "100%",
          marginTop: "10px",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='body2'>{message}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              required
              error={formData?.firstName?.error}
              value={formData?.firstName?.value}
              label='First Name'
              name='firstName'
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              fullWidth
              required
              error={formData?.lastName?.error}
              label='Last Name'
              name='lastName'
              value={formData?.lastName?.value}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={formData?.email?.error}
              type='email'
              label='Email'
              name='email'
              value={formData?.email?.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={formData?.subject?.error}
              label='Subject'
              name='subject'
              value={formData?.subject?.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              error={formData?.message?.error}
              variant='outlined'
              minRows={isMobile ? 5 : 10}
              maxRows={8}
              fullWidth
              multiline
              value={formData?.message?.value}
              label='Message'
              name='message'
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button
            type='submit'
            variant='contained'
            onClick={handleSubmit}
            fullWidth={isMobile}
          >
            Submit
          </Button>
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </motion.div>
  );
};

export default Contact;
