import { Typography, useTheme } from "@mui/material";
import { useIsMobile } from "Hooks";
import { Instagram, Facebook, Email } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "10px",
        paddingTop: "10px",
        flexDirection: "column",
      }}
    >
      <Grid container justifyContent='space-around' sx={{ maxWidth: "200px" }}>
        <a
          href='https://www.instagram.com/videogamealliance/'
          target='_blank'
          rel='noreferrer'
          style={{ color: palette.primary.main }}
        >
          <Instagram fontSize='large' />
        </a>
        <a
          href='https://www.facebook.com/VideoGameAllianceATL'
          target='_blank'
          rel='noreferrer'
          style={{ color: palette.primary.main }}
        >
          <Facebook fontSize='large' />
        </a>
        <Link
          to='/contact'
          style={{ color: palette.primary.main, textDecoration: "none" }}
        >
          <Email fontSize='large' />
        </Link>
      </Grid>

      {isMobile ? (
        <>
          <Typography variant='caption' color='text.secondary'>
            All Rights Reserved. Video Game Alliance ©
            {new Date().getFullYear()}
          </Typography>
          <Typography variant='caption' color='text.secondary'>
            Design by{" "}
            <a
              href='https://zacevanscoding.com'
              target='_blank'
              rel='noreferrer'
              style={{ color: palette.primary.main }}
            >
              Zac Evans
            </a>
          </Typography>
        </>
      ) : (
        <Typography variant='caption' color='text.secondary'>
          All Rights Reserved. Video Game Alliance ©{new Date().getFullYear()}{" "}
          |{" "}
          <a
            href='https://zacevanscoding.com'
            target='_blank'
            rel='noreferrer'
            style={{ color: palette.primary.main }}
          >
            Design by Zac Evans
          </a>
        </Typography>
      )}
    </div>
  );
};

export default Footer;
