import { Header } from "Components";

const HeaderContent = {
  title: "Video Game Alliance",
  subtitle: "",
  showGifs: true,
  element: <Header />,
  disableOnClick: true,

  // Update fontSize, fontFamily, fontWeight, color, etc.
  titleStyle: { fontFamily: "Kanit, sans-serif", fontWeight: "500" },
  headerImage: {
    src: "https://video-game-alliance-assets.s3.amazonaws.com/logos/logo1-transparent.png",
    alt: "Video Game Alliance",
  },
  hideTextTitle: true,
  subTitleStyle: { fontWeight: "300", fontSize: "1.4rem" },
  navigationStyle: { fontSize: "1.5rem" },
  navigationTypographyProps: {
    fontFamily: "Pixelify Sans, sans-serif",
    fontWeight: "500",
  },
  listItemTextProps: {
    primaryTypographyProps: {
      sx: {
        fontFamily: "Pixelify Sans, sans-serif",
        fontWeight: "500",
      },
    },
  },
  pageTitleTypographyProps: {
    sx: {
      fontFamily: "Pixelify Sans, sans-serif",
      fontWeight: "500",
      textTransform: "uppercase",
    },
  },
  menuStyle: { fontFamily: "Pixelify Sans, sans-serif", fontWeight: "500" },
  headlines: [
    "100% Gamer",
    "I can't believe it's not video game music",
    "Like if Battletoads formed a funk-fusion band",
    "Big Jazz Nerds",
    "Shout out to Koji Kondo",
    "Shout out to Nobuo Uematsu",
    "Gotta go fast (on the saxophone)",
    "It's a me, VGA!",
    "Shout out to Grant Kirkhope",
    "Funky Kong is the best Kong",
    "We will play the DK Rap... for the right price",
    "Like if Sephiroth believed in the power of music",
    "Like if the Doom Slayer slayed the drums instead of demons (but also demons)",
    "Please don't sue us, Nintendo",
    "Dragoncon, please hire us.  We're begging you.",
    "We're not just a band, we're a band with a website",
    "Follow us on Instagram @videogamealliance",
    "Follow us on Facebook!",
    "Dunna dunna dunna",
    "We play the music, you play the games",
    "I modded my GameCube controller to play the saxophone",
    "** sings halo theme **",
    "If you're reading this, you're a nerd (but also cool)",
    "I 3D printed a saxophone, and it sounds terrible",
    "We play music on hard mode",
    "Down Throw, Short Hop Dair, Fast Fall Nair, Short Hop Dair, Falling Up Air, Up B",
    "up up down down left right left right B A START",
    "Shout out to David Wise",
    "Shout out to Yoko Shimomura",
    "Video Game Alliance is a collective of nerds who happen to also be musicians",
    "Pikachu... play me something funky",
  ],
  headlinesStyle: {
    fontFamily: "Pixelify Sans, sans-serif",
    fontWeight: "500",
  },
};

export default HeaderContent;
