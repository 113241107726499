import { Face } from "@mui/icons-material";
import { ImageCarousel } from "Pages";

const ImageCarouselContent = {
  title: "",
  images: [
    {
      mobileObjectPosition: "right top",
      desktopObjectPosition: "right top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/posters/poster1.jpg",
    },
    {
      mobileObjectPosition: "-40px  top",
      desktopObjectPosition: "right top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-95.jpeg",
    },
    {
      mobileObjectPosition: "right top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA3-3.jpg",
    },
    {
      mobileObjectPosition: "60% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA032023-8.jpg",
    },
    {
      mobileObjectPosition: "50% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-28.jpg",
    },
    {
      mobileObjectPosition: "right top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-72.jpg",
    },
    {
      mobileObjectPosition: "10% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-74.jpg",
    },
    {
      mobileObjectPosition: "right top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA-Luke-Weathington-99.jpg",
    },
    {
      mobileObjectPosition: "20% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA032023-13.jpg",
    },
    {
      mobileObjectPosition: "40% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA032023-17.jpg",
    },
    {
      mobileObjectPosition: "20% top",
      src: "https://video-game-alliance-assets.s3.amazonaws.com/images/VGA032023-7.jpg",
    },
  ],
  interval: 6000,
  icon: <Face />,
  path: "/",
  home: true,
  hidden: false,
  menu: false,
  menuIndex: 2,
  element: <ImageCarousel />,
};

export default ImageCarouselContent;
