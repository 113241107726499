import { OndemandVideo } from "@mui/icons-material";
import { Videos } from "Pages";

const VideosContent = {
  title: "Videos",
  playlistID: "PLSSiOHuH9TerQltwsNEBGrcJzD1NF_jb3",
  icon: <OndemandVideo />,
  path: "/videos",
  hidden: false,
  // subMenu: false,
  menu: true,
  menuIndex: 1,
  element: <Videos />,
};
export default VideosContent;
